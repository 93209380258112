@charset "UTF-8";
/* Accordions */
.accordion-custom .card {
  border: none;
  border-radius: 0.25rem; }

.accordion-custom .card-header,
.accordion-custom .card-block {
  background: #fff;
  border: 1px solid #eee; }

.accordion-custom .card-header {
  padding: 0; }

.accordion-custom .card-heading {
  display: block;
  width: 100%;
  cursor: pointer;
  padding: 1rem;
  position: relative;
  font-size: 1rem;
  font-weight: 400; }

.accordion-custom .card-block {
  padding: 1rem;
  font-size: 1rem;
  color: #6a6c6f;
  line-height: 1.6; }

.accordion-custom .card-heading::after {
  content: '\f067';
  font: normal normal normal 14px/1 FontAwesome;
  line-height: 2rem;
  font-size: 1rem;
  text-align: center;
  display: inline-block;
  color: #6a6c6f;
  position: absolute;
  right: 1rem;
  top: .75rem; }

.accordion-custom .card-heading[aria-expanded="true"]::after {
  content: '\f068'; }

/* Alerts */
.alert {
  border-radius: 0; }
  .alert .close {
    opacity: .3; }

.alert-rounded {
  border-radius: 0.25rem; }

.alert-outline {
  background: none; }
  .alert-outline.alert-info {
    color: #2196F3;
    border-color: #2196F3; }
    .alert-outline.alert-info .close {
      color: #2196F3; }
  .alert-outline.alert-success {
    color: #4CAF50;
    border-color: #4CAF50; }
    .alert-outline.alert-success .close {
      color: #4CAF50; }
  .alert-outline.alert-warning {
    color: #FF9800;
    border-color: #FF9800; }
    .alert-outline.alert-warning .close {
      color: #FF9800; }
  .alert-outline.alert-danger {
    color: #F44336;
    border-color: #F44336; }
    .alert-outline.alert-danger .close {
      color: #F44336; }
  .alert-outline .close {
    opacity: 1; }

/* Custom animations */
@keyframes scaleIn {
  from {
    transform: scale(0); }
  to {
    transform: scale(1); } }

.scaleInDownLeft {
  animation-duration: .25s;
  transform-origin: right top;
  animation-name: scaleIn; }

.scaleInDownRight {
  animation-duration: .25s;
  transform-origin: left top;
  animation-name: scaleIn; }

/* Avatars */
.avatar {
  position: relative;
  width: 3rem;
  height: 3rem;
  border-radius: 3px;
  margin-right: 1rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 500;
  color: #fff; }

.avatar img,
.avatar a > img {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  display: block;
  border-radius: 3px; }

.avatar-circle,
.avatar-circle img {
  border-radius: 100% !important; }

.avatar-sm {
  width: 2.5rem;
  height: 2.5rem; }

.avatar-lg {
  width: 4rem;
  height: 4rem;
  font-size: 1.25rem;
  font-weight: bold; }

/* Background variants */
.bg-none {
  background: none !important; }

.bg-white {
  background-color: #fff !important; }

.bg-black {
  background-color: #282828 !important;
  color: #fff !important; }

.bg-inverse {
  background-color: #3b3e47 !important;
  color: #fff !important; }

.bg-pink {
  background-color: #E91E63 !important;
  color: #fff !important; }

.bg-purple {
  background-color: #9C27B0 !important;
  color: #fff !important; }

.bg-gray {
  background-color: #bbb !important;
  color: #fff !important; }

.bg-eee {
  background: #eee !important; }

.bg-faded {
  background: #f5f7f9 !important; }

.bg-facebook,
.bg-hv-facebook:hover {
  background-color: #3b5998 !important;
  color: #fff !important; }

.bg-twitter,
.bg-hv-twitter:hover {
  background-color: #1da1f2 !important;
  color: #fff !important; }

.bg-google-plus,
.bg-hv-google-plus:hover {
  background-color: #dd4b39 !important;
  color: #fff !important; }

.bg-youtube,
.bg-hv-youtube:hover {
  background-color: #cd201f !important;
  color: #fff !important; }

.bg-linkedin,
.bg-hv-linkedin:hover {
  background-color: #0077b5 !important;
  color: #fff !important; }

.bg-flickr,
.bg-hv-flickr:hover {
  background-color: #ff0084 !important;
  color: #fff !important; }

.bg-tumblr,
.bg-hv-tumblr:hover {
  background-color: #35465c !important;
  color: #fff !important; }

.bg-dribbble,
.bg-hv-dribbble:hover {
  background-color: #ea4c89 !important;
  color: #fff !important; }

.bg-red-500 {
  background-color: #F44336 !important; }

.bg-red-700 {
  background-color: #D32F2F !important; }

.bg-pink-500 {
  background-color: #E91E63 !important; }

.bg-pink-700 {
  background-color: #C2185B !important; }

.bg-purple-500 {
  background-color: #9C27B0 !important; }

.bg-purple-700 {
  background-color: #7B1FA2 !important; }

.bg-indigo-500 {
  background-color: #3F51B5 !important; }

.bg-indigo-700 {
  background-color: #303F9F !important; }

.bg-blue-500 {
  background-color: #2196F3 !important; }

.bg-blue-700 {
  background-color: #1976D2 !important; }

.bg-cyan-500 {
  background-color: #00BCD4 !important; }

.bg-cyan-700 {
  background-color: #0097A7 !important; }

.bg-teal-500 {
  background-color: #009688 !important; }

.bg-teal-700 {
  background-color: #00796B !important; }

.bg-green-500 {
  background-color: #4CAF50 !important; }

.bg-green-700 {
  background-color: #388E3C !important; }

.bg-yellow-500 {
  background-color: #FFEB3B !important; }

.bg-yellow-700 {
  background-color: #FBC02D !important; }

.bg-orange-500 {
  background-color: #FF9800 !important; }

.bg-orange-700 {
  background-color: #F57C00 !important; }

.bg-brown-500 {
  background-color: #795548 !important; }

.bg-brown-700 {
  background-color: #5D4037 !important; }

.bg-gray-500 {
  background-color: #9E9E9E !important; }

.bg-gray-700 {
  background-color: #616161 !important; }

/* Borders */
.border-a-0 {
  border: none !important; }

.border-a-1 {
  border: 1px solid #eee !important; }

.border-a-2 {
  border: 2px solid #eee !important; }

.border-t-0 {
  border-top: none !important; }

.border-t-1 {
  border-top: 1px solid #eee !important; }

.border-t-2 {
  border-top: 2px solid #eee !important; }

.border-r-0 {
  border-right: none !important; }

.border-r-1 {
  border-right: 1px solid #eee !important; }

.border-r-2 {
  border-right: 2px solid #eee !important; }

.border-b-0 {
  border-bottom: none !important; }

.border-l-0 {
  border-left: none !important; }

.border-b-1 {
  border-bottom: 1px solid #eee !important; }

.border-b-2 {
  border-bottom: 2px solid #eee !important; }

.border-l-1 {
  border-left: 1px solid #eee !important; }

.border-l-2 {
  border-left: 2px solid #eee !important; }

.border-color-primary {
  border-color: #3F51B5 !important; }

.border-color-success {
  border-color: #4CAF50 !important; }

.border-color-warning {
  border-color: #FF9800 !important; }

.border-color-danger {
  border-color: #F44336 !important; }

/* Buttons */
.btn {
  outline: none !important; }
  .btn:focus {
    outline: none; }

.btn-sm,
.btn-group-sm > .btn {
  font-size: .75rem; }

.btn-icon {
  padding: 0;
  line-height: 2rem;
  width: 2rem;
  height: 2rem;
  border: 1px solid #eee;
  cursor: pointer; }
  .btn-icon.btn-sm {
    line-height: 1.5rem;
    width: 1.5rem;
    height: 1.5rem; }
  .btn-icon.btn-lg {
    line-height: 2.5rem;
    width: 2.5rem;
    height: 2.5rem; }

.btn-icon-flip {
  position: relative;
  overflow: hidden; }
  .btn-icon-flip, .btn-icon-flip:focus, .btn-icon-flip:active {
    outline: none;
    box-shadow: none; }
  .btn-icon-flip i {
    border-radius: inherit;
    transition: top .25s;
    line-height: inherit;
    position: absolute;
    display: block;
    height: 100%;
    width: 100%;
    top: 0; }
    .btn-icon-flip i:last-child {
      top: 100%; }
  .btn-icon-flip:hover i:first-child {
    top: -100%; }
  .btn-icon-flip:hover i:last-child {
    top: 0; }

.btn-default {
  color: #292b2c;
  background-color: #fff;
  border-color: #ccc; }
  .btn-default:hover {
    color: #292b2c;
    background-color: #e6e6e6;
    border-color: #adadad; }
  .btn-default:focus, .btn-default.focus {
    box-shadow: 0 0 0 2px rgba(204, 204, 204, 0.5); }
  .btn-default.disabled, .btn-default:disabled {
    background-color: #fff;
    border-color: #ccc; }
  .btn-default:active, .btn-default.active,
  .show > .btn-default.dropdown-toggle {
    color: #292b2c;
    background-color: #e6e6e6;
    background-image: none;
    border-color: #adadad; }

.btn-rounded {
  border-radius: 1000px; }

.social-icon {
  font-size: 1rem;
  margin-right: .25rem;
  margin-left: .25rem; }

/* Cards */
.card {
  margin-bottom: 24px;
  border-radius: 0; }

.card-toolbar {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  margin-right: -.75rem; }
  .card-toolbar > li > a {
    padding: .5rem .75rem;
    font-size: 1rem; }

.card-header {
  padding: .75rem 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center; }
  .card-header:after {
    display: none; }

.card-heading {
  margin: 0;
  font-size: 1rem; }

.card-img-top {
  width: 100%;
  border-radius: 0;
  height: auto;
  max-width: 100%; }

.card-img-overlay {
  padding: 1.5rem; }

.card .table {
  margin: 0; }
  .card .table tr:first-child td {
    border-top: none; }
  .card .table th,
  .card .table td {
    padding: .875rem 1.5rem; }

.cover {
  position: relative; }

.cover-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.5rem; }

/* Charts customization */
#jqstooltip {
  display: block;
  background: #36404a;
  color: #fff;
  border-radius: 3px;
  padding: 8px 16px !important;
  width: auto !important;
  height: auto !important;
  border: none;
  white-space: nowrap; }

#pieLegend .legendColorBox > div {
  border: none !important; }
  #pieLegend .legendColorBox > div > div {
    border-width: 7px !important; }

#pieLegend .legendLabel, #pieLegend .legendColorBox {
  padding: .2rem 0 .4rem 0; }

#pieLegend .legend-label {
  font-weight: 500;
  margin-top: .5em;
  margin-left: .5em; }

.flotTip {
  background: #36404a;
  color: #fff;
  padding: 8px 16px;
  border-radius: 3px;
  font-family: lato, Helvetica, serif; }

.small-chart {
  margin-top: 4px; }

.chart {
  height: 300px;
  width: 100%; }

.morris-hover.morris-default-style {
  border-radius: 5px;
  padding: 10px 12px;
  color: #666;
  background: #36404a;
  border: none;
  color: #ffffff !important; }
  .morris-hover.morris-default-style .morris-hover-point {
    color: #fff !important; }

.chart-legend {
  margin-bottom: 16px;
  text-align: center; }
  .chart-legend .chart-legend-label .fa {
    display: inline-block;
    margin-right: 6px; }

.sparkline-chart canvas {
  display: block !important;
  margin: 0 auto; }

.pieprogress {
  position: relative; }
  .pieprogress strong {
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    text-align: center;
    line-height: 40px;
    font-size: 20px; }

/* Circles */
.circle {
  width: 3rem;
  height: 3rem;
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center; }

.circle-xs {
  width: 2rem;
  height: 2rem; }

.circle-sm {
  width: 2.5rem;
  height: 2.5rem; }

.circle-lg {
  width: 4rem;
  height: 4rem; }

/* 
Custom Checkox and radio elements
inspired with bootstrap-awesome-checkbox library
*/
.checkbox {
  position: relative;
  padding-left: 22px;
  display: block;
  margin-top: .5rem;
  margin-bottom: .5rem; }
  .checkbox label {
    position: relative;
    padding-left: 10px;
    margin: 0;
    min-height: 22px;
    vertical-align: middle;
    cursor: pointer; }
    .checkbox label:empty {
      padding-left: 0; }
  .checkbox label::before,
  .checkbox label::after,
  .checkbox input[type="checkbox"] {
    height: 22px;
    width: 22px;
    position: absolute;
    left: 0;
    top: 0; }
  .checkbox label::before,
  .checkbox label::after {
    content: '';
    margin-left: -22px; }
  .checkbox label::before {
    background: #fff;
    border: 1px solid #eee;
    transition: background .15s, border-color .15s;
    border-radius: 0.25rem; }
  .checkbox label::after {
    line-height: 22px;
    color: inherit;
    font-family: "FontAwesome";
    text-align: center;
    background-color: transparent;
    font-style: normal;
    color: #818a91;
    font-size: .875rem; }
  .checkbox input[type="checkbox"] {
    opacity: 0;
    z-index: 1;
    cursor: pointer; }
    .checkbox input[type="checkbox"]:checked + label::after {
      content: ""; }
    .checkbox input[type="checkbox"]:indeterminate + label::after {
      display: block;
      content: "";
      width: 10px;
      height: 3px;
      background-color: #555555;
      border-radius: 2px;
      margin-left: -16.5px;
      margin-top: 7px; }
    .checkbox input[type="checkbox"]:disabled {
      cursor: not-allowed; }
    .checkbox input[type="checkbox"]:disabled + label {
      opacity: 0.65;
      cursor: not-allowed; }
      .checkbox input[type="checkbox"]:disabled + label::before {
        background-color: #f5f7f9; }
  .checkbox.checkbox-circle label::before {
    border-radius: 50%; }
  .checkbox.checkbox-square label::before {
    border-radius: 0; }
  .checkbox.checkbox-lg {
    padding-left: 26px; }
    .checkbox.checkbox-lg label {
      padding-left: 12px;
      min-height: 26px; }
    .checkbox.checkbox-lg label::before,
    .checkbox.checkbox-lg label::after,
    .checkbox.checkbox-lg input[type="checkbox"] {
      width: 26px;
      height: 26px; }
    .checkbox.checkbox-lg label::before,
    .checkbox.checkbox-lg label::after {
      margin-left: -26px; }
    .checkbox.checkbox-lg label::after {
      line-height: 26px;
      font-size: 1rem; }
  .checkbox.checkbox-inline {
    display: inline-block; }

.radio {
  position: relative;
  padding-left: 20px;
  display: block;
  margin-top: .5rem;
  margin-bottom: .5rem; }
  .radio label {
    position: relative;
    padding-left: .5rem;
    margin: 0;
    min-height: 20px;
    vertical-align: middle;
    cursor: pointer; }
    .radio label:empty {
      padding-left: 0; }
  .radio label::before,
  .radio input[type="radio"] {
    height: 20px;
    width: 20px;
    position: absolute;
    left: 0;
    top: 0; }
  .radio label::before,
  .radio label::after {
    content: ' ';
    margin-left: -20px; }
  .radio label::before {
    border: 1px solid #ccc;
    border-radius: 50%;
    background-color: #fff;
    transition: border 0.15s ease-in-out; }
  .radio label::after {
    position: absolute;
    width: 12px;
    height: 12px;
    left: 4px;
    top: 4px;
    border-radius: 50%;
    background-color: #818a91;
    transform: scale(0, 0);
    transition: transform 0.1s cubic-bezier(0.8, -0.33, 0.2, 1.33); }
  .radio input[type="radio"] {
    opacity: 0;
    z-index: 1;
    cursor: pointer; }
    .radio input[type="radio"]:checked + label::after {
      transform: scale(1, 1); }
    .radio input[type="radio"]:disabled {
      cursor: not-allowed; }
    .radio input[type="radio"]:disabled + label {
      opacity: 0.65;
      cursor: not-allowed; }
      .radio input[type="radio"]:disabled + label::before {
        cursor: not-allowed; }
  .radio.radio-inline {
    display: inline-block; }

.checkbox-red input[type="checkbox"]:checked + label::before,
.checkbox-red input[type="radio"]:checked + label::before {
  background-color: #F44336;
  border-color: #F44336; }

.checkbox-red input[type="checkbox"]:checked + label::after,
.checkbox-red input[type="radio"]:checked + label::after {
  color: #fff; }

.checkbox-pink input[type="checkbox"]:checked + label::before,
.checkbox-pink input[type="radio"]:checked + label::before {
  background-color: #E91E63;
  border-color: #E91E63; }

.checkbox-pink input[type="checkbox"]:checked + label::after,
.checkbox-pink input[type="radio"]:checked + label::after {
  color: #fff; }

.checkbox-purple input[type="checkbox"]:checked + label::before,
.checkbox-purple input[type="radio"]:checked + label::before {
  background-color: #9C27B0;
  border-color: #9C27B0; }

.checkbox-purple input[type="checkbox"]:checked + label::after,
.checkbox-purple input[type="radio"]:checked + label::after {
  color: #fff; }

.checkbox-indigo input[type="checkbox"]:checked + label::before,
.checkbox-indigo input[type="radio"]:checked + label::before {
  background-color: #3F51B5;
  border-color: #3F51B5; }

.checkbox-indigo input[type="checkbox"]:checked + label::after,
.checkbox-indigo input[type="radio"]:checked + label::after {
  color: #fff; }

.checkbox-blue input[type="checkbox"]:checked + label::before,
.checkbox-blue input[type="radio"]:checked + label::before {
  background-color: #2196F3;
  border-color: #2196F3; }

.checkbox-blue input[type="checkbox"]:checked + label::after,
.checkbox-blue input[type="radio"]:checked + label::after {
  color: #fff; }

.checkbox-cyan input[type="checkbox"]:checked + label::before,
.checkbox-cyan input[type="radio"]:checked + label::before {
  background-color: #00BCD4;
  border-color: #00BCD4; }

.checkbox-cyan input[type="checkbox"]:checked + label::after,
.checkbox-cyan input[type="radio"]:checked + label::after {
  color: #fff; }

.checkbox-teal input[type="checkbox"]:checked + label::before,
.checkbox-teal input[type="radio"]:checked + label::before {
  background-color: #009688;
  border-color: #009688; }

.checkbox-teal input[type="checkbox"]:checked + label::after,
.checkbox-teal input[type="radio"]:checked + label::after {
  color: #fff; }

.checkbox-green input[type="checkbox"]:checked + label::before,
.checkbox-green input[type="radio"]:checked + label::before {
  background-color: #4CAF50;
  border-color: #4CAF50; }

.checkbox-green input[type="checkbox"]:checked + label::after,
.checkbox-green input[type="radio"]:checked + label::after {
  color: #fff; }

.checkbox-yellow input[type="checkbox"]:checked + label::before,
.checkbox-yellow input[type="radio"]:checked + label::before {
  background-color: #FFEB3B;
  border-color: #FFEB3B; }

.checkbox-yellow input[type="checkbox"]:checked + label::after,
.checkbox-yellow input[type="radio"]:checked + label::after {
  color: #fff; }

.checkbox-orange input[type="checkbox"]:checked + label::before,
.checkbox-orange input[type="radio"]:checked + label::before {
  background-color: #FF9800;
  border-color: #FF9800; }

.checkbox-orange input[type="checkbox"]:checked + label::after,
.checkbox-orange input[type="radio"]:checked + label::after {
  color: #fff; }

.checkbox-brown input[type="checkbox"]:checked + label::before,
.checkbox-brown input[type="radio"]:checked + label::before {
  background-color: #795548;
  border-color: #795548; }

.checkbox-brown input[type="checkbox"]:checked + label::after,
.checkbox-brown input[type="radio"]:checked + label::after {
  color: #fff; }

.checkbox-gray input[type="checkbox"]:checked + label::before,
.checkbox-gray input[type="radio"]:checked + label::before {
  background-color: #9E9E9E;
  border-color: #9E9E9E; }

.checkbox-gray input[type="checkbox"]:checked + label::after,
.checkbox-gray input[type="radio"]:checked + label::after {
  color: #fff; }

.checkbox-primary input[type="checkbox"]:checked + label::before,
.checkbox-primary input[type="radio"]:checked + label::before {
  background-color: #3F51B5;
  border-color: #3F51B5; }

.checkbox-primary input[type="checkbox"]:checked + label::after,
.checkbox-primary input[type="radio"]:checked + label::after {
  color: #fff; }

.checkbox-danger input[type="checkbox"]:checked + label::before,
.checkbox-danger input[type="radio"]:checked + label::before {
  background-color: #F44336;
  border-color: #F44336; }

.checkbox-danger input[type="checkbox"]:checked + label::after,
.checkbox-danger input[type="radio"]:checked + label::after {
  color: #fff; }

.checkbox-info input[type="checkbox"]:checked + label::before,
.checkbox-info input[type="radio"]:checked + label::before {
  background-color: #2196F3;
  border-color: #2196F3; }

.checkbox-info input[type="checkbox"]:checked + label::after,
.checkbox-info input[type="radio"]:checked + label::after {
  color: #fff; }

.checkbox-warning input[type="checkbox"]:checked + label::before,
.checkbox-warning input[type="radio"]:checked + label::before {
  background-color: #FF9800;
  border-color: #FF9800; }

.checkbox-warning input[type="checkbox"]:checked + label::after,
.checkbox-warning input[type="radio"]:checked + label::after {
  color: #fff; }

.checkbox-success input[type="checkbox"]:checked + label::before,
.checkbox-success input[type="radio"]:checked + label::before {
  background-color: #4CAF50;
  border-color: #4CAF50; }

.checkbox-success input[type="checkbox"]:checked + label::after,
.checkbox-success input[type="radio"]:checked + label::after {
  color: #fff; }

.checkbox-dark input[type="checkbox"]:checked + label::before,
.checkbox-dark input[type="radio"]:checked + label::before {
  background-color: #282828;
  border-color: #282828; }

.checkbox-dark input[type="checkbox"]:checked + label::after,
.checkbox-dark input[type="radio"]:checked + label::after {
  color: #fff; }

.checkbox-red input[type="checkbox"]:indeterminate + label::before,
.checkbox-red input[type="radio"]:indeterminate + label::before {
  background-color: #F44336;
  border-color: #F44336; }

.checkbox-red input[type="checkbox"]:indeterminate + label::after,
.checkbox-red input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.checkbox-pink input[type="checkbox"]:indeterminate + label::before,
.checkbox-pink input[type="radio"]:indeterminate + label::before {
  background-color: #E91E63;
  border-color: #E91E63; }

.checkbox-pink input[type="checkbox"]:indeterminate + label::after,
.checkbox-pink input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.checkbox-purple input[type="checkbox"]:indeterminate + label::before,
.checkbox-purple input[type="radio"]:indeterminate + label::before {
  background-color: #9C27B0;
  border-color: #9C27B0; }

.checkbox-purple input[type="checkbox"]:indeterminate + label::after,
.checkbox-purple input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.checkbox-indigo input[type="checkbox"]:indeterminate + label::before,
.checkbox-indigo input[type="radio"]:indeterminate + label::before {
  background-color: #3F51B5;
  border-color: #3F51B5; }

.checkbox-indigo input[type="checkbox"]:indeterminate + label::after,
.checkbox-indigo input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.checkbox-blue input[type="checkbox"]:indeterminate + label::before,
.checkbox-blue input[type="radio"]:indeterminate + label::before {
  background-color: #2196F3;
  border-color: #2196F3; }

.checkbox-blue input[type="checkbox"]:indeterminate + label::after,
.checkbox-blue input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.checkbox-cyan input[type="checkbox"]:indeterminate + label::before,
.checkbox-cyan input[type="radio"]:indeterminate + label::before {
  background-color: #00BCD4;
  border-color: #00BCD4; }

.checkbox-cyan input[type="checkbox"]:indeterminate + label::after,
.checkbox-cyan input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.checkbox-teal input[type="checkbox"]:indeterminate + label::before,
.checkbox-teal input[type="radio"]:indeterminate + label::before {
  background-color: #009688;
  border-color: #009688; }

.checkbox-teal input[type="checkbox"]:indeterminate + label::after,
.checkbox-teal input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.checkbox-green input[type="checkbox"]:indeterminate + label::before,
.checkbox-green input[type="radio"]:indeterminate + label::before {
  background-color: #4CAF50;
  border-color: #4CAF50; }

.checkbox-green input[type="checkbox"]:indeterminate + label::after,
.checkbox-green input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.checkbox-yellow input[type="checkbox"]:indeterminate + label::before,
.checkbox-yellow input[type="radio"]:indeterminate + label::before {
  background-color: #FFEB3B;
  border-color: #FFEB3B; }

.checkbox-yellow input[type="checkbox"]:indeterminate + label::after,
.checkbox-yellow input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.checkbox-orange input[type="checkbox"]:indeterminate + label::before,
.checkbox-orange input[type="radio"]:indeterminate + label::before {
  background-color: #FF9800;
  border-color: #FF9800; }

.checkbox-orange input[type="checkbox"]:indeterminate + label::after,
.checkbox-orange input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.checkbox-brown input[type="checkbox"]:indeterminate + label::before,
.checkbox-brown input[type="radio"]:indeterminate + label::before {
  background-color: #795548;
  border-color: #795548; }

.checkbox-brown input[type="checkbox"]:indeterminate + label::after,
.checkbox-brown input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.checkbox-gray input[type="checkbox"]:indeterminate + label::before,
.checkbox-gray input[type="radio"]:indeterminate + label::before {
  background-color: #9E9E9E;
  border-color: #9E9E9E; }

.checkbox-gray input[type="checkbox"]:indeterminate + label::after,
.checkbox-gray input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.checkbox-primary input[type="checkbox"]:indeterminate + label::before,
.checkbox-primary input[type="radio"]:indeterminate + label::before {
  background-color: #3F51B5;
  border-color: #3F51B5; }

.checkbox-primary input[type="checkbox"]:indeterminate + label::after,
.checkbox-primary input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.checkbox-danger input[type="checkbox"]:indeterminate + label::before,
.checkbox-danger input[type="radio"]:indeterminate + label::before {
  background-color: #F44336;
  border-color: #F44336; }

.checkbox-danger input[type="checkbox"]:indeterminate + label::after,
.checkbox-danger input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.checkbox-info input[type="checkbox"]:indeterminate + label::before,
.checkbox-info input[type="radio"]:indeterminate + label::before {
  background-color: #2196F3;
  border-color: #2196F3; }

.checkbox-info input[type="checkbox"]:indeterminate + label::after,
.checkbox-info input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.checkbox-warning input[type="checkbox"]:indeterminate + label::before,
.checkbox-warning input[type="radio"]:indeterminate + label::before {
  background-color: #FF9800;
  border-color: #FF9800; }

.checkbox-warning input[type="checkbox"]:indeterminate + label::after,
.checkbox-warning input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.checkbox-success input[type="checkbox"]:indeterminate + label::before,
.checkbox-success input[type="radio"]:indeterminate + label::before {
  background-color: #4CAF50;
  border-color: #4CAF50; }

.checkbox-success input[type="checkbox"]:indeterminate + label::after,
.checkbox-success input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.checkbox-dark input[type="checkbox"]:indeterminate + label::before,
.checkbox-dark input[type="radio"]:indeterminate + label::before {
  background-color: #282828;
  border-color: #282828; }

.checkbox-dark input[type="checkbox"]:indeterminate + label::after,
.checkbox-dark input[type="radio"]:indeterminate + label::after {
  background-color: #fff; }

.radio-red input[type="radio"] + label::before {
  border-color: #F44336; }

.radio-red input[type="radio"] + label::after {
  background-color: #F44336; }

.radio-red input[type="radio"]:checked + label::before {
  border-color: #F44336; }

.radio-red input[type="radio"]:checked + label::after {
  background-color: #F44336; }

.radio-pink input[type="radio"] + label::before {
  border-color: #E91E63; }

.radio-pink input[type="radio"] + label::after {
  background-color: #E91E63; }

.radio-pink input[type="radio"]:checked + label::before {
  border-color: #E91E63; }

.radio-pink input[type="radio"]:checked + label::after {
  background-color: #E91E63; }

.radio-purple input[type="radio"] + label::before {
  border-color: #9C27B0; }

.radio-purple input[type="radio"] + label::after {
  background-color: #9C27B0; }

.radio-purple input[type="radio"]:checked + label::before {
  border-color: #9C27B0; }

.radio-purple input[type="radio"]:checked + label::after {
  background-color: #9C27B0; }

.radio-indigo input[type="radio"] + label::before {
  border-color: #3F51B5; }

.radio-indigo input[type="radio"] + label::after {
  background-color: #3F51B5; }

.radio-indigo input[type="radio"]:checked + label::before {
  border-color: #3F51B5; }

.radio-indigo input[type="radio"]:checked + label::after {
  background-color: #3F51B5; }

.radio-blue input[type="radio"] + label::before {
  border-color: #2196F3; }

.radio-blue input[type="radio"] + label::after {
  background-color: #2196F3; }

.radio-blue input[type="radio"]:checked + label::before {
  border-color: #2196F3; }

.radio-blue input[type="radio"]:checked + label::after {
  background-color: #2196F3; }

.radio-cyan input[type="radio"] + label::before {
  border-color: #00BCD4; }

.radio-cyan input[type="radio"] + label::after {
  background-color: #00BCD4; }

.radio-cyan input[type="radio"]:checked + label::before {
  border-color: #00BCD4; }

.radio-cyan input[type="radio"]:checked + label::after {
  background-color: #00BCD4; }

.radio-teal input[type="radio"] + label::before {
  border-color: #009688; }

.radio-teal input[type="radio"] + label::after {
  background-color: #009688; }

.radio-teal input[type="radio"]:checked + label::before {
  border-color: #009688; }

.radio-teal input[type="radio"]:checked + label::after {
  background-color: #009688; }

.radio-green input[type="radio"] + label::before {
  border-color: #4CAF50; }

.radio-green input[type="radio"] + label::after {
  background-color: #4CAF50; }

.radio-green input[type="radio"]:checked + label::before {
  border-color: #4CAF50; }

.radio-green input[type="radio"]:checked + label::after {
  background-color: #4CAF50; }

.radio-yellow input[type="radio"] + label::before {
  border-color: #FFEB3B; }

.radio-yellow input[type="radio"] + label::after {
  background-color: #FFEB3B; }

.radio-yellow input[type="radio"]:checked + label::before {
  border-color: #FFEB3B; }

.radio-yellow input[type="radio"]:checked + label::after {
  background-color: #FFEB3B; }

.radio-orange input[type="radio"] + label::before {
  border-color: #FF9800; }

.radio-orange input[type="radio"] + label::after {
  background-color: #FF9800; }

.radio-orange input[type="radio"]:checked + label::before {
  border-color: #FF9800; }

.radio-orange input[type="radio"]:checked + label::after {
  background-color: #FF9800; }

.radio-brown input[type="radio"] + label::before {
  border-color: #795548; }

.radio-brown input[type="radio"] + label::after {
  background-color: #795548; }

.radio-brown input[type="radio"]:checked + label::before {
  border-color: #795548; }

.radio-brown input[type="radio"]:checked + label::after {
  background-color: #795548; }

.radio-gray input[type="radio"] + label::before {
  border-color: #9E9E9E; }

.radio-gray input[type="radio"] + label::after {
  background-color: #9E9E9E; }

.radio-gray input[type="radio"]:checked + label::before {
  border-color: #9E9E9E; }

.radio-gray input[type="radio"]:checked + label::after {
  background-color: #9E9E9E; }

.radio-primary input[type="radio"] + label::before {
  border-color: #3F51B5; }

.radio-primary input[type="radio"] + label::after {
  background-color: #3F51B5; }

.radio-primary input[type="radio"]:checked + label::before {
  border-color: #3F51B5; }

.radio-primary input[type="radio"]:checked + label::after {
  background-color: #3F51B5; }

.radio-danger input[type="radio"] + label::before {
  border-color: #F44336; }

.radio-danger input[type="radio"] + label::after {
  background-color: #F44336; }

.radio-danger input[type="radio"]:checked + label::before {
  border-color: #F44336; }

.radio-danger input[type="radio"]:checked + label::after {
  background-color: #F44336; }

.radio-info input[type="radio"] + label::before {
  border-color: #2196F3; }

.radio-info input[type="radio"] + label::after {
  background-color: #2196F3; }

.radio-info input[type="radio"]:checked + label::before {
  border-color: #2196F3; }

.radio-info input[type="radio"]:checked + label::after {
  background-color: #2196F3; }

.radio-warning input[type="radio"] + label::before {
  border-color: #FF9800; }

.radio-warning input[type="radio"] + label::after {
  background-color: #FF9800; }

.radio-warning input[type="radio"]:checked + label::before {
  border-color: #FF9800; }

.radio-warning input[type="radio"]:checked + label::after {
  background-color: #FF9800; }

.radio-success input[type="radio"] + label::before {
  border-color: #4CAF50; }

.radio-success input[type="radio"] + label::after {
  background-color: #4CAF50; }

.radio-success input[type="radio"]:checked + label::before {
  border-color: #4CAF50; }

.radio-success input[type="radio"]:checked + label::after {
  background-color: #4CAF50; }

.radio-dark input[type="radio"] + label::before {
  border-color: #282828; }

.radio-dark input[type="radio"] + label::after {
  background-color: #282828; }

.radio-dark input[type="radio"]:checked + label::before {
  border-color: #282828; }

.radio-dark input[type="radio"]:checked + label::after {
  background-color: #282828; }

/* Dropdowns */
.dropdown-menu {
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.175);
  border: none; }

.dropdown-item {
  color: #6a6c6f; }
  .dropdown-item:active {
    color: #fff; }

/* 
override bootstrap defaults
Some form-specific variables
*/
.form-group {
  margin-bottom: 24px; }

.form-control {
  border-radius: 3px;
  height: 38px;
  box-shadow: none;
  border-color: #ddd; }
  .form-control:focus, .form-control:active {
    border-color: #ccc;
    outline: none;
    box-shadow: none; }

.input-sm {
  height: 30px;
  padding: 5px 10px;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 3px; }

.input-lg {
  height: 46px;
  padding: 10px 16px;
  font-size: 18px;
  line-height: 1.3333333;
  border-radius: 6px; }

textarea.form-control {
  min-height: 100px; }

label {
  font-size: 14px;
  font-weight: 500; }

.radio,
.checkbox {
  margin-top: 0;
  margin-bottom: 12px; }

.form-inline .form-control {
  min-width: 250px; }

div.dataTables_wrapper div.dataTables_length select {
  width: 100px;
  height: 30px;
  font-weight: 600;
  font-size: 14px; }

div.dataTables_wrapper div.dataTables_filter input {
  width: 200px;
  height: 30px; }

.select2-selection {
  height: 38px !important; }
  .select2-selection.select2-selection--single {
    height: 38px !important; }
    .select2-selection.select2-selection--single .select2-selection__rendered {
      line-height: 38px !important; }
  .select2-selection .select2-selection__choice {
    margin-top: 8px !important; }
  .select2-selection .select2-selection__arrow {
    height: 38px !important; }

.bootstrap-tagsinput {
  width: 100%;
  border-radius: 3px;
  min-height: 38px;
  box-shadow: none;
  border-color: #ddd; }
  .bootstrap-tagsinput:focus, .bootstrap-tagsinput:active {
    border-color: #ccc;
    outline: none;
    box-shadow: none; }

.bootstrap-touchspin .btn.bootstrap-touchspin-down,
.bootstrap-touchspin .btn.bootstrap-touchspin-up {
  height: 38px;
  font-size: 18px; }

.bootstrap-touchspin .input-group-addon {
  border-left: 0;
  border-right: 0; }

.bootstrap-touchspin .input-group-btn-vertical > .btn {
  height: 20px;
  width: 25px; }
  .bootstrap-touchspin .input-group-btn-vertical > .btn i {
    left: 25%; }

/* Icons */
.icon {
  width: 2rem;
  height: 2rem;
  border: 1px solid #eee;
  justify-content: center;
  align-items: center;
  display: inline-flex; }

.icon-circle {
  border-radius: 100%; }

.icon-sm {
  width: 1.5rem;
  height: 1.5rem; }

.icon-lg {
  width: 3rem;
  height: 3rem; }

/* 
  imaage gallery component
*/
.g__item {
  margin-bottom: 24px;
  display: block; }
  .g__item .g__figure {
    display: block;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    z-index: 1;
    margin: 0; }
  .g__item .g__img,
  .g__item .g__figure > img {
    width: 100%;
    height: auto; }

.g__thumb {
  padding: .5rem;
  background-color: #f5f7f9;
  border: 1px solid #eee; }

.g__item.rounded .g__figure, .g__item.rounded img {
  border-radius: 0.25rem; }

.g__overlay {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  top: 0;
  width: 100%;
  height: 100%;
  padding: 1rem;
  opacity: 0;
  transition: opacity 1s; }
  .g__item:hover .g__overlay {
    opacity: 1;
    background-color: rgba(255, 255, 255, 0.9); }

.g__overlay-title {
  line-height: 1.4;
  font-style: italic;
  margin-bottom: .5rem; }

.g__overlay-text {
  font-style: italic;
  font-size: 1rem; }

.g__zoom {
  align-self: center;
  color: #3F51B5; }

.img-gallery-zoom-effect .g__item .g__figure > img,
.img-gallery-zoom-effect .g__item .g__img {
  transition: transform 1s; }

.img-gallery-zoom-effect .g__item:hover .g__figure > img,
.img-gallery-zoom-effect .g__item:hover .g__img {
  transform: scale(1.3); }

/* Media component */
.media {
  margin-top: 0; }

.media small {
  color: #818a91;
  font-size: 12px; }

.media-heading {
  margin-bottom: .25rem;
  margin-top: .25rem;
  font-size: 1rem; }

.media-meta {
  font-size: 0.875rem; }

.media-list .media {
  margin: 0;
  padding: .75rem 1.25rem;
  border-bottom: 1px solid #f5f7f9; }
  .media-list .media:last-child {
    border-bottom: none; }

.media-list a.media:hover, .media-list a.media:focus {
  background-color: #f5f7f9; }

/* Modal Component */
.modal-backdrop.show {
  opacity: .8; }

.modal .modal-dialog {
  max-width: 600px; }

#video-modal {
  padding: 0 !important; }
  #video-modal .modal-dialog {
    width: 460px;
    height: 250px;
    max-width: 100%;
    box-shadow: none;
    margin: 60px auto !important;
    background: #000; }
  #video-modal .modal-content {
    width: 100%;
    height: 100%;
    background: #000; }
  #video-modal iframe {
    width: 100%;
    height: 100%;
    border: none;
    display: block; }
  @media (min-width: 576px) {
    #video-modal .modal-dialog {
      width: 560px;
      height: 315px; } }
  @media (min-width: 768px) {
    #video-modal .modal-dialog {
      width: 660px;
      height: 380px; } }
  @media (min-width: 992px) {
    #video-modal .modal-dialog {
      width: 760px;
      height: 440px; } }

/* Poster component */
.poster {
  position: relative;
  height: 100%;
  width: 100%; }

.poster-bg {
  height: 100%;
  width: 100%; }

.poster-overlay {
  justify-content: center;
  align-items: center;
  position: absolute;
  display: flex;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0; }

.poster-overlay-icon {
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1000px;
  border: 10px solid rgba(0, 0, 0, 0.2);
  background: #fff;
  color: none; }

/* Customizing Progress bars */
.progress-xs .progress-bar {
  height: .5rem; }

.progress-sm .progress-bar {
  height: .75rem; }

.progress-md .progress-bar {
  height: 1rem; }

.progress-lg .progress-bar {
  height: 1.25rem; }

.progress-xl .progress-bar {
  height: 1.5rem; }

/* 
scaffolding and general resets and styles
*/
body {
  font-size: 0.875rem;
  font-weight: 300;
  font-family: Raleway, "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #6a6c6f; }
  body.no-padding:not(.menubar-top) .site-content {
    padding-right: 0;
    padding-left: 0; }
  body.no-padding .site-content {
    padding-top: 0;
    padding-bottom: 0; }
  body.site-header-hidden .site-header {
    display: none; }

a {
  color: #6a6c6f; }
  a, a:hover, a:focus {
    text-decoration: none; }

.site-menu, .site-menu ul, .site-menu ol {
  padding: 0;
  margin: 0; }

.site-menu li {
  list-style-type: none; }

svg {
  vertical-align: middle; }

.hamburger {
  background: none; }
  .hamburger:focus {
    outline: none; }

.jqvmap-zoomin, .jqvmap-zoomout {
  box-sizing: content-box; }

.scroll-container {
  position: relative;
  overflow: auto;
  height: 100%; }

.ps-scrollbar-y-rail {
  width: 4px !important;
  background: rgba(0, 0, 0, 0.2); }

.ps-scrollbar-y {
  background: #bbb !important;
  width: 4px !important; }

.jqstooltip {
  box-sizing: content-box; }

.flotTip {
  background: #3F51B5 !important;
  color: #fff !important;
  border: none !important;
  padding: .5rem 1rem !important;
  border-radius: 0.25rem;
  font-family: inherit;
  font-size: .875rem !important; }

table.table-noborder td, table.table-noborder th {
  border-top: none; }

table.table-noborder tr:nth-child(2) td {
  padding-top: 1.5rem; }

td {
  font-weight: 300; }

th {
  font-weight: 500; }

.thead-success th {
  color: #fff;
  background-color: #4CAF50; }

.form-control {
  box-shadow: none;
  font-size: 1em; }

.modal-content {
  box-shadow: none;
  border: none; }

iframe {
  border: 0; }

/* Status component */
.status {
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 100%;
  position: absolute;
  right: 0;
  bottom: 0;
  border: 2px solid #fff; }

.status-online {
  background-color: #4CAF50; }

.status-away {
  background-color: #FF9800; }

.status-busy {
  background-color: #F44336; }

.status-offline {
  background-color: #eceeef; }

/* Customizing Tables */
.table {
  margin-bottom: 0; }
  .table th {
    font-size: 14px;
    font-weight: 600; }

.table.no-cellborder {
  margin-top: -2px;
  table-layout: fixed; }
  .table.no-cellborder th, .table.no-cellborder td {
    border: none;
    padding: 8px 0; }
  .table.no-cellborder .table-icon {
    margin-right: 6px;
    color: #ccc; }

.fixedHeader-floating {
  top: 64px !important;
  z-index: 90; }
  .fixedHeader-floating th {
    background: #edf0f5; }

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #F6F6F6; }

.dt-buttons {
  display: block;
  max-width: 100%;
  width: 300px;
  margin: 0 auto;
  margin-bottom: 16px; }
  .dt-buttons .btn {
    background-color: #F6F6F6; }

/* Text variants */
.text-color {
  color: #6a6c6f !important; }

.title-color {
  color: #373a3c !important; }

.watermark {
  color: #edf0f5 !important; }

.text-muted {
  color: inherit;
  opacity: .6 !important; }

.text-white {
  color: white !important; }

.text-gray {
  color: #aaa !important; }

.text-inverse {
  color: #3b3e47 !important; }

.text-black {
  color: #282828 !important; }

.text-red {
  color: #F44336 !important; }

.text-pink {
  color: #E91E63 !important; }

.text-purple {
  color: #9C27B0 !important; }

.text-indigo {
  color: #3F51B5 !important; }

.text-blue {
  color: #2196F3 !important; }

.text-cyan {
  color: #00BCD4 !important; }

.text-teal {
  color: #009688 !important; }

.text-green {
  color: #4CAF50 !important; }

.text-yellow {
  color: #FFEB3B !important; }

.text-orange {
  color: #FF9800 !important; }

.text-brown {
  color: #795548 !important; }

.text-facebook,
.text-hv-facebook:hover {
  color: #3b5998 !important; }

.text-twitter,
.text-hv-twitter:hover {
  color: #1da1f2 !important; }

.text-google-plus,
.text-hv-google-plus:hover {
  color: #dd4b39 !important; }

.text-youtube,
.text-hv-youtube:hover {
  color: #cd201f !important; }

.text-dribbble,
.text-hv-dribbble:hover {
  color: #ea4c89 !important; }

.text-flickr,
.text-hv-flickr:hover {
  color: #ff0084 !important; }

.text-linkedin,
.text-hv-linkedin:hover {
  color: #0077b5 !important; }

.text-tumblr,
.text-hv-tumblr:hover {
  color: #35465c !important; }

/* Typing */
h1, h2, h3,
.h1, .h2, .h3 {
  font-weight: 300; }

h4, h5, h6,
.h4, .h5, .h6 {
  font-weight: 400; }

small {
  font-size: 85%; }

/* Utility Classes */
.theme-color {
  color: #3F51B5; }

.flex-grow-1 {
  flex-grow: 1; }

.pos-s {
  position: static !important; }

.pos-r {
  position: relative !important; }

.badge {
  font-size: 10px;
  font-weight: 500; }

.badge-circle {
  border-radius: 50%; }

.border-radius-0 {
  border-radius: 0 !important; }

.border-radius-1 {
  border-radius: 0.25rem !important; }

.fz-base {
  font-size: 1rem !important; }

.fz-xs {
  font-size: 0.75rem !important; }

.fz-sm {
  font-size: 0.875rem !important; }

.fz-lg {
  font-size: 1.25rem !important; }

.lh-2 {
  line-height: 1.8 !important; }

.font-weight-200 {
  font-weight: 200 !important; }

.font-weight-light {
  font-weight: 300 !important; }

.d-table {
  display: table !important; }
  .d-table > * {
    display: table-cell !important; }

.img-full {
  width: 100% !important;
  height: auto;
  display: block; }

@media (min-width: 768px) {
  .modal-md {
    max-width: 700px; } }

/* [8. Widgets / .widget] */
.box,
.widget {
  background-color: #fff;
  margin-bottom: 1.5rem;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.14);
  border-radius: 2px;
  overflow: hidden; }
  .box small,
  .widget small {
    font-size: 95%;
    font-weight: 500; }

.widget-header,
.widget-body,
.widget-footer {
  padding: 1rem; }
  .widget-header::after,
  .widget-body::after,
  .widget-footer::after {
    display: block;
    content: "";
    clear: both; }

.widget-header {
  display: flex; }

.widget-title {
  font-size: 1rem;
  font-weight: 500;
  margin: 0;
  color: #373a3c; }

h3.widget-title {
  font-size: 20px;
  margin-bottom: 16px;
  font-weight: 500; }

.widget-separator {
  margin: 0 16px; }

.widget p {
  line-height: 1.8; }

.sl-item {
  position: relative;
  padding-bottom: 12px;
  border-left: 1px solid #ccc; }
  .sl-item:before {
    content: '';
    position: absolute;
    left: -6px;
    top: 0;
    background-color: #ccc;
    width: 12px;
    height: 12px;
    border-radius: 100%; }
  .sl-item:last-child:after {
    content: '';
    position: absolute;
    left: -3px;
    bottom: 0;
    background-color: #ccc;
    width: 6px;
    height: 6px;
    border-radius: 100%; }
  .sl-item .sl-content {
    margin-left: 24px; }
    .sl-item .sl-content p {
      margin: 0; }

.sl-primary {
  border-left-color: #3F51B5; }
  .sl-primary:before, .sl-primary:last-child:after {
    background-color: #3F51B5; }

.sl-success {
  border-left-color: #4CAF50; }
  .sl-success:before, .sl-success:last-child:after {
    background-color: #4CAF50; }

.sl-warning {
  border-left-color: #FF9800; }
  .sl-warning:before, .sl-warning:last-child:after {
    background-color: #FF9800; }

.sl-danger {
  border-left-color: #F44336; }
  .sl-danger:before, .sl-danger:last-child:after {
    background-color: #F44336; }

.sl-avatar {
  position: absolute;
  left: -22px; }

/* shared components styles */
/* [7. Search / .site-search] */
.site-search {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  display: none; }

.site-search-form {
  z-index: 1;
  position: relative;
  margin-top: 100px;
  margin-right: 5%;
  margin-left: 5%;
  width: 90%; }
  .site-search-form .search-field {
    border: none;
    outline: none;
    box-shadow: none;
    background: none;
    font-size: 3rem;
    color: #333;
    border-bottom: 3px solid #333;
    padding-left: 64px;
    padding-right: 24px;
    width: 100%; }
  .site-search-form .search-icon {
    position: absolute;
    left: 0;
    top: 0;
    font-size: 3rem; }
  @media (max-width: 767px) {
    .site-search-form .search-field {
      font-size: 2rem;
      padding-left: 48px; }
    .site-search-form .search-icon {
      font-size: 2rem; } }

.site-search-close {
  width: 30px;
  height: 30px;
  border: 1px solid #fff;
  background: none;
  border-radius: 5000px;
  position: absolute;
  right: -15px;
  top: -15px;
  cursor: pointer;
  color: #fff; }

.site-search-backdrop {
  background: rgba(0, 0, 0, 0.9);
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  z-index: -1; }

body.simple-page {
  background-color: #3F51B5; }

.simple-page-wrap {
  display: flex;
  align-items: center;
  justify-content: center; }

.simple-page-header {
  padding: 1rem .5rem; }
  .simple-page-header .home-btn .btn {
    font-size: 2rem;
    color: #fff;
    border: none;
    opacity: .4;
    transition: opacity .5s; }
    .simple-page-header .home-btn .btn:hover, .simple-page-header .home-btn .btn:focus, .simple-page-header .home-btn .btn:active {
      background: none;
      border: none;
      opacity: 1;
      outline: none;
      box-shadow: none; }

.simple-page-content {
  max-width: 90%;
  width: 320px; }

.simple-page-logo {
  text-align: center;
  font-size: 24px;
  margin-bottom: 24px; }
  .simple-page-logo a {
    color: #fff; }

.simple-page-form {
  background-color: #fff;
  border-radius: 6px;
  padding: 24px;
  margin-bottom: 24px; }
  .simple-page-form .form-group {
    margin-bottom: 32px; }
  .simple-page-form input,
  .simple-page-form input:focus,
  .simple-page-form input:active {
    outline: none;
    box-shadow: none; }
  .simple-page-form input {
    border: none;
    border-bottom: 1px solid #eee;
    height: 40px; }
  .simple-page-form .btn {
    width: 100%; }
